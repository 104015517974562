<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <!-- <v-toolbar-side-icon> -->
      <v-btn icon @click.prevent="back_to_datamenu()">
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
      <!-- </v-toolbar-side-icon> -->
      <v-toolbar-title @click.prevent="test()">เลขอั้น / รับแทงสูงสุด</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn>Link One</v-btn>
        <v-btn>Link Two</v-btn>
        <v-btn>Link Three</v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-menu class="hidden-md-and-up">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <!-- <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->
        <v-list>
          <v-list-tile>
            <v-btn flat>Link One</v-btn>
            <v-btn flat>Link Two</v-btn>
            <v-btn flat>Link Three</v-btn>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-tabs
      
      background-color="white"
      show-arrows
    >
      <v-tabs-slider color="black"></v-tabs-slider>

      <v-tab
        v-for="i in 30"
        :key="i"
        :href="'#tab-' + i"
      >
        Item {{ i }}
      </v-tab>
    </v-tabs>


        <v-subheader>เลขอั้น
<v-spacer></v-spacer>
จัดการ
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        </v-subheader>

    <v-data-table
      :headers="headers"
      :items="limit_data"
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <!-- <template v-slot:[`item.bet_type`]="{ item }"> -->
      <!-- จัดการข้างใน -->
      <!-- {{ item.bet_type }} -->
      <!-- </template> -->
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td class="headcol">  {{ getHpName(item.bet_type) }}</td>
            <td>{{ item.number }}</td>
            <td>{{ item.addition }}</td>
            <td>{{ item.prize }}</td>
            <td>CONTENT</td>
            <td>CONTENT</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- ----------------------------------------- -->

    <v-data-table
      :headers="headers"
      :items="limit_data"
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.bet_type`]="{ item }">
        <v-chip :color="getColor(item.bet_type)" dark>
          {{ item.bet_type }}
        </v-chip>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers"
      :items="limit_data"
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.bet_type`]="{ item }">
        <v-chip :color="getColor(item.bet_type)" dark>
          {{ item.bet_type }}
        </v-chip>
      </template>
    </v-data-table>

    <!-- <v-btn fab dark large color="primary" fixed right bottom>
        <v-icon dark>add</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import globalFunctionMixin from "../../mixins/globalFunctionMixin";

export default {
  name: "Limit_Number",
   mixins: [globalFunctionMixin],
  data: () => ({
      model: null,
    headers: [
      //   {
      //     text: "Dessert (100g serving)",
      //     align: "start",
      //     sortable: false,
      //     value: "name",
      //   },
      {
        text: "ชนิด",
        align: "start",
        value: "bet_type",
        width: "70",
      },
      { text: "เลข", value: "number", align: "start",width: "50", },
      { text: "เพิ่มเติม", value: "addition",width: "80", },
      { text: "ราคา", value: "prize",width: "80", },
      { text: "เวลาอั้น", value: "time_begin" },
      { text: "เวลาหยุด", value: "time_end" },
    ],
    limit_data: [
      {
        name: "Frozen Yogurt",
        bet_type: 1,
        number: "000",
        addition: "-",
        prize: "จ่ายครึ่ง",
        time_begin: 4.0,
        time_end: "1%",
        status: 0,
      },
      {
        name: "Ice cream sandwich",
        bet_type: 1,
        number: "000",
        addition: "-",
        prize: "จ่ายครึ่ง",
        time_begin: 4.3,
        time_end: "1%",
      },
      {
        name: "Eclair",
        bet_type: 2,
        number: "000",
        addition: "-",
        prize: 23,
        time_begin: 6.0,
        time_end: "7%",
      },
      {
        name: "Cupcake",
        bet_type: 1,
        number: "000",
        addition: "-",
        prize: 67,
        time_begin: 4.3,
        time_end: "8%",
      },
      {
        name: "Gingerbread",
        bet_type: 1,
        number: "000",
        addition: "-",
        prize: 49,
        time_begin: 3.9,
        time_end: "16%",
      },
      {
        name: "Jelly bean",
        bet_type: 2,
        number: "000",
        addition: "-",
        prize: 94,
        time_begin: 0.0,
        time_end: "0%",
      },
      {
        name: "Lollipop",
        bet_type: 3,
        number: "000",
        addition: "-",
        prize: 98,
        time_begin: 0,
        time_end: "2%",
      },
      {
        name: "Honeycomb",
        bet_type: 2,
        number: "000",
        addition: "-",
        prize: 87,
        time_begin: 6.5,
        time_end: "45%",
      },
      {
        name: "Donut",
        bet_type: 1,
        number: "000",
        addition: "-",
        prize: 51,
        time_begin: 4.9,
        time_end: "22%",
      },
      {
        name: "KitKat",
        bet_type: 1,
        number: "000",
        addition: "x 6 หาง",
        prize: 65,
        time_begin: 7,
        time_end: "6%",
      },
    ],
  }),
  methods: {
    getHpName(hpType) {
        switch (hpType){
            case 1:
                return "3-บน";
            case 2:
                return "3-โต๊ด"
            case 3:
                return "2-บ+ล"
        }
    },
    getColor(betType) {
      if (betType == "3บน") return "red";
      else if (betType == "2บน+ล่าง") return "orange";
      else return "green";
    },
    rowClick(name) {
      this.selectedItem = name;
    },
    back_to_datamenu() {
      this.$router.push("/Menu_Data");
    },
  },
};
</script>

<style scoped>

</style>
